import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import { Container } from "react-bootstrap";
import { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";

const otpLength = 6;

const OTPInput = ({ confirm, isSubmitting, phone }) => {
  const [otp, setOtp] = useState();

  const handleChange = (e) => {
    setOtp(e.target.value);
  };

  return (
    <>
      <Row>
        <Col>
          <h5>
            <b>We sent a text to your phone</b>
          </h5>
          <p>
            To continue, please enter the 6-digit verification code sent to your
            phone ending in {phone?.slice(phone?.length - 4, phone?.length)}.
          </p>
        </Col>
      </Row>
      <Row xs={12} md={8} className="justify-content-md-center">
        <Col>
          <InputGroup
            className="mb-3"
            style={{
              textAlign: "center",
            }}
          >
            <FormControl
              aria-label="Code"
              type="tel"
              autoComplete="one-time-code"
              name="otc"
              maxLength={6}
              style={{
                textAlign: "center",
                marginBottom: 20,
                // increase height
                height: "50px",
                fontWeight: "bold",
                fontSize: "1.2em",
                // character spacing
                letterSpacing: "1.5em",
              }}
              onChange={handleChange}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row
        style={{ marginTop: 20 }}
        xs={12}
        md={8}
        className="justify-content-md-center"
      >
        <Col>
          <InputGroup>
            <Button
              name="submit0btn"
              disabled={isSubmitting}
              style={{
                marginBottom: 20,
                backgroundColor: "#4538ffcc",
                height: "50px",
              }}
              onClick={() => confirm(otp)}
              block
            >
              Verify pin and continue{" "}
              {isSubmitting ? (
                <Spinner animation="border" size="sm" variant="dark" />
              ) : null}
            </Button>
          </InputGroup>
          <br />
        </Col>
      </Row>
    </>
  );
};

export default OTPInput;
