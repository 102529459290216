import React, { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";

const AutogateOnlineReportBySiteComponent = ({ onlineData }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Show Autogate Online Report By Site
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Autogate Online Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>State</th>
                <th>Site</th>
                <th>Online</th>
              </tr>
            </thead>
            <tbody>
              {onlineData?.map((state) => {
                return state?.siteArray?.map((sa, idx) => {
                  return (
                    <tr key={`${sa.Site}_${idx}`}>
                      <td>{state.State || ""}</td>
                      <td>{sa.Site || ""}</td>
                      <td>{Object.keys(sa)?.length - 1}</td>
                    </tr>
                  );
                });
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AutogateOnlineReportBySiteComponent;
