import { useEffect, useState } from "react";
import { useUser } from "reactfire";

function useIdTokenResult(user, requiredClaims) {
  const [claims, setClaims] = useState({});
  useEffect(() => {
    user
      .getIdTokenResult()
      .then((idTokenResult) => {
        if (idTokenResult?.claims) {
          //   console.log("setting claims", idTokenResult.claims);
          setClaims(idTokenResult?.claims);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [user]);

  return claims;
}

function ClaimsCheck({ user, fallback, children, requiredClaims }) {
  const claims = useIdTokenResult(user, requiredClaims);
  const missingClaims = [{}];

  //   if (typeof claims === "undefined") {
  //   console.log("mcLength", missingClaims.length);
  //   }

  if (requiredClaims) {
    Object.keys(requiredClaims).forEach((claim) => {
      //   console.log("parsing claims", claim);
      if (requiredClaims[claim] !== claims[claim]) {
        // console.log("missing claim expected ", requiredClaims[claim]);
        // console.log("missing claim actual", claims[claim]);
        missingClaims[claim] = {
          expected: requiredClaims[claim],
          actual: claims[claim],
        };
      }
    });
  }

  if (Object.keys(missingClaims).length === 1) {
    return <>{children}</>;
  } else {
    return <>{fallback}</>;
  }
}

export function AuthCheckCustom({ fallback, children, requiredClaims }) {
  const { data: user } = useUser();

  if (user) {
    // console.log("user available");
    return requiredClaims ? (
      <ClaimsCheck
        user={user}
        fallback={fallback}
        requiredClaims={requiredClaims}
      >
        {children}
      </ClaimsCheck>
    ) : (
      <>{children}</>
    );
  } else {
    return <>{fallback}</>;
  }
}
