import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import { useEffect } from "react";
import { Alert, Button, Dropdown, DropdownButton } from "react-bootstrap";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";

import OTPInput from "./OTPInput";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  getAuth,
  OAuthProvider,
  signInWithPopup,
  signInWithRedirect,
} from "firebase/auth";
import DALogo from "../assets/images/DALogo.png";
import { useFirebaseApp } from "reactfire";

const provider = new OAuthProvider("microsoft.com");
provider.setCustomParameters({
  // Force re-consent.
  // prompt: "consent",
  tenant: "6e5646b8-64cf-4878-bda9-5a1023b136a1",
  // client_id: "c1c3ea7c-ed68-4a8a-9b7d-ee725c7d2925",
  // response_type: "id_token",
  // response_mode: "query",
  // redirect_uri: "https://vehicle-imaging.web.app/__/auth/handler",
  // Target specific email with login hint.
  login_hint: "user@duttongroup.com.au",
});
provider.addScope("User.Read.all");
// import PageViewLoggerComponent from "./PageViewLoggerComponent";

const styles = {
  body: {
    fontFamily: "Arial, sans-serif",
  },
  loginPage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: "100vh",
    backgroundColor: "#f6f9fc",
  },
  loginContainer: {
    padding: "20px",
    backgroundColor: "#fff",
    borderRadius: "5px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  },
};

const allowedCountryCodes = ["+61", "+81"];

const LoginFormComponent = () => {
  const [phone, setPhone] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [resendRequestCount, setResendRequestCount] = useState(0);
  const [isOTPSubmitting, setIsOTPSubmitting] = useState(false);
  const [appVerifier, setAppVerifier] = useState(undefined);
  const [countryCode, setCountryCode] = useState(allowedCountryCodes[0]);
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [email, setEmail] = useState("");
  const [showPhoneInput, setShowPhoneInput] = useState(false);

  const app = useFirebaseApp();
  const auth = getAuth(app);
  // const auth = useAuth();
  auth.languageCode = "en";

  const handleToggleEmailInput = () => {
    setShowEmailInput(!showEmailInput);
  };

  const handleChangeEmailSignin = (e) => {
    setEmail(e.target.value);
  };

  const onSignInSubmit = async () => {
    let phoneNumber = "";

    if (typeof appVerifier === "undefined") {
      console.log("Verifier is undefined");
      return;
    }

    if (typeof phone === "undefined" || phone?.toString().length < 8) {
      toast.error(`Please enter a valid mobile number ${phone}`);
      return;
    } else {
      phoneNumber = countryCode + phone.toString();
      toast.success(
        `You will receive an SMS to ${phoneNumber} with a one time pin`
      );
    }

    await signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((cR) => {
        setConfirmationResult(cR);
        setResendRequestCount(resendRequestCount + 1);
      })
      .catch((e) => {
        console.log("phone, error", e);
        let message = "";

        switch (e.code) {
          case "auth/captcha-check-failed":
            message = "Captcha error";
            break;

          case "auth/too-many-requests":
            message =
              "Too many attempts from this computer, please wait and try again later";
            break;

          case "auth/invalid-phone-number":
            message = "Phone number format not recognised";
            break;

          case "auth/invalid-verification-code":
            message = "Invalid verification code";
            break;

          default:
            message = e.message;
        }
        toast.error(message);
      });
  };

  const resendOTP = () => {
    setResendRequestCount(resendRequestCount + 1);
    if (typeof appVerifier !== "undefined") {
      appVerifier.render().then((widgetId) => {
        // grecaptcha.reset(widgetId);
        setAppVerifier(widgetId);
        onSignInSubmit();
      });
    }
  };

  const handleChange = (e) => {
    setPhone(e.target.value);
  };

  const confirmOTP = (code) => {
    if (confirmationResult === null) {
      toast.error("Error with verification, please retry");

      return;
    } else if (typeof code === "undefined" || code.length < 6) {
      toast.error("Please enter 6 digit code");
      return;
    } else {
      setIsOTPSubmitting(true);
      const otpcode = code.toString();
      confirmationResult
        .confirm(otpcode)
        .then((result) => {})
        .catch((e) => {
          console.log("otp error", e);
          switch (e.code) {
            case "auth/user-disabled":
              toast.error(
                "Thank you for registering, an administrator will be in touch with you to enable your account once you are verified as an employee"
              );
              break;
            default:
              toast.error(e.message);
          }
          setIsOTPSubmitting(false);
        });
    }
  };

  // MSFT login
  const handleMicrosoftLogin = async () => {
    // toast.error(`Coming soon...`);
    // return;
    toast.success("Logging in with Microsoft");
    try {
      // If dev mode, use signinwithpopup
      if (window.location.hostname === "localhost") {
        return await signInWithPopup(auth, provider);
      }
      // If prod mode, use signinwithredirect

      await signInWithRedirect(auth, provider);

      // return await signInWithPopup(auth, provider)
      //   .then((result) => {
      //     // User is signed in.
      //     // IdP data available in result.additionalUserInfo.profile.
      //     console.log(result);
      //     // // Get the OAuth access token and ID Token
      //     const credential = OAuthProvider.credentialFromResult(result);
      //     const accessToken = credential.accessToken;
      //     const idToken = credential.idToken;
      //     return;
      //   })
      //   .catch((error) => {
      //     // Handle error.
      //     // console.log("error", error);
      //     // toast.error("Error logging into the Data Portal");
      //     toast.error(error.message);
      //   });
    } catch (e) {
      // console.log("error", e);
      toast.error("Error logging in");
    }
  };

  // Initialise firebase recapthca

  useEffect(() => {
    // auth.appVerificationDisabledForTesting = true;
    // auth.settings.appVerificationDisabledForTesting = true;
    const tmpappVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
      size: "invisible",
      callback: (response, err) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        if (err) console.log("capt", err);
        onSignInSubmit();
      },
      "expired-callback": () => {
        console.log("expired");
      },
    });
    setAppVerifier(tmpappVerifier);
  }, []);

  useState(() => {
    if (confirmationResult !== null) {
    }
  }, [confirmationResult]);

  return (
    <>
      {/* Container to make this in the middle of the page*/}
      {/* Add DALogo image */}

      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          padding: "50px",
          backgroundColor: "#f6f9fc",
          // bottom 3d shadow
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Row className={styles.loginPage}>
          <Col className={styles.loginContainer}>
            {confirmationResult === null ? (
              <>
                <Row>
                  <Col>
                    <img
                      src={DALogo}
                      alt="Dutton Data Portal"
                      style={{
                        width: "60%",
                        // width: "25%",
                        marginBottom: 40,
                        marginTop: 30,
                        maxWidth: 200,
                      }}
                    />
                    <h5
                      style={{
                        marginBottom: 50,
                        fontWeight: 400,
                        textAlign: "center",
                      }}
                    >
                      <b
                        style={{
                          fontWeight: 400,
                          fontSize: 18,
                        }}
                      >
                        Sign in to continue
                      </b>
                    </h5>

                    <Button
                      variant="outline-dark"
                      onClick={() => handleMicrosoftLogin()}
                      style={{ padding: 15 }}
                    >
                      <svg
                        style={{ marginRight: 0 }}
                        height="20"
                        viewBox="0 0 20 20"
                        width="20"
                        fit=""
                        preserveAspectRatio="xMidYMid meet"
                        focusable="false"
                      >
                        <g fill="none">
                          <path d="M0 0h9.504v9.504H0z" fill="#F25022"></path>
                          <path
                            d="M10.496 0H20v9.504h-9.504z"
                            fill="#7FBA00"
                          ></path>
                          <path d="M0 10.496h9.504V20H0z" fill="#00A4EF"></path>
                          <path
                            d="M10.496 10.496H20V20h-9.504z"
                            fill="#FFB900"
                          ></path>
                        </g>
                      </svg>
                      &nbsp;&nbsp; Log in with your Dutton ID
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <br />
                    <br />
                    <Button
                      variant="link"
                      onClick={() => setShowPhoneInput(!showPhoneInput)}
                      style={{
                        color: "black",
                        cursor: "pointer",
                        textDecoration: "underline",
                        // display: "none",
                      }}
                    >
                      Log in with your mobile number
                    </Button>
                  </Col>
                </Row>
                {showPhoneInput && (
                  <Row
                    style={{ marginTop: 20 }}
                    className="justify-content-md-center"
                  >
                    <Col>
                      <hr />
                      <Alert
                        variant="info"
                        style={{
                          maxWidth: 400,
                          textAlign: "left",
                          fontWeight: 400,
                          fontSize: 14,
                        }}
                      >
                        The phone number option will be removed soon. Please try
                        to your Dutton ID to log in and let IT know if you have
                        any issues.
                      </Alert>
                      <p style={{ textAlign: "left" }}>Phone</p>
                      <InputGroup
                        style={{
                          marginBottom: 20,
                          // increase height
                          height: "50px",
                        }}
                      >
                        <DropdownButton
                          as={InputGroup.Prepend}
                          disabled={isOTPSubmitting || showEmailInput}
                          style={{
                            marginBottom: 20,
                            height: "50px",
                          }}
                          variant="outline-dark"
                          title={countryCode}
                          id="input-group-dropdown-country-code"
                          size="sm"
                        >
                          <Dropdown.Item
                            onClick={(e) => {
                              setCountryCode("+61");
                            }}
                          >
                            +61
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setCountryCode("+81");
                            }}
                          >
                            +81
                          </Dropdown.Item>
                        </DropdownButton>
                        {/* <InputGroup.Prepend>
                        <InputGroup.Text
                          style={{
                            marginBottom: 20,
                            // increase height
                            height: "50px",
                          }}
                          id="inputGroup-sizing-sm"
                        >
                          {countryCode}
                        </InputGroup.Text>
                      </InputGroup.Prepend> */}
                        <Form.Control
                          style={{
                            marginBottom: 20,
                            // increase height
                            height: "50px",
                            letterSpacing: 1.5,
                            border: "1px solid #4538ffcc",
                          }}
                          aria-label="phone"
                          disabled={isOTPSubmitting || showEmailInput}
                          name="phone"
                          type="tel"
                          value={phone}
                          onChange={handleChange}
                          aria-describedby="inputGroup-sizing-lg"
                        />
                      </InputGroup>
                      <InputGroup>
                        <Button
                          id="phone-submit"
                          onClick={onSignInSubmit}
                          disabled={isOTPSubmitting || showEmailInput}
                          block
                          style={{
                            marginBottom: 20,
                            backgroundColor: "#4538ffcc",
                            // increase height
                            height: "50px",
                          }}
                        >
                          Login
                        </Button>
                      </InputGroup>
                    </Col>
                  </Row>
                )}
              </>
            ) : (
              <>
                <OTPInput
                  confirm={confirmOTP}
                  phone={phone}
                  isSubmitting={isOTPSubmitting}
                />

                <Button variant="link" onClick={resendOTP}>
                  Resend code
                </Button>
              </>
            )}
            <div id="recaptcha-container"></div>
            <Toaster />
            {/* <PageViewLoggerComponent /> */}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LoginFormComponent;
