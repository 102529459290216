import { Button, Card, Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const sections = [
  {
    name: "Freight",
    id: "freight-section",
    components: [
      {
        name: "New Freight Booking",
        description: "Use this form to create a new freight booking request",
        link: "/freight-booking-form",
      },
    ],
  },
  {
    name: "Tools",
    id: "tools-section",
    components: [
      {
        name: "VIN / Stock Number Search",
        description:
          "A global search for vehicles by VIN or Eclipse Stock Number",
        link: "/vin-rego-search",
      },
    ],
  },
  {
    name: "Payments",
    id: "payments-section",
    components: [
      {
        name: "New Desposit Request",
        description: "A form to request a new deposit for a vehicle",
        link: "/deposit-payment-request",
      },
    ],
  },
];
export default function Start() {
  const history = useHistory();
  return (
    <>
      <Container>
        <Row>
          <Col>
            <p>
              {/* Explain the main features of this program */}
              Welcome to the Dutton Data Portal.
            </p>
            <h5 style={{ fontWeight: 400, textAlign: "left" }}>Quick Links</h5>
          </Col>
        </Row>
        <Row>
          {sections.map((s, keyi) => {
            return s.components.map((c, keyj) => {
              return (
                <Col
                  style={{ display: "flex", justifyContent: "center" }}
                  xs={12}
                  sm={5}
                  md={3}
                  key={`${keyi}-${keyj}`}
                >
                  <Card style={{ width: "18rem" }}>
                    <Card.Title
                      style={{
                        fontSize: 18,
                        paddingTop: 20,
                        fontWeight: 400,
                      }}
                    >
                      {c.name}
                      <hr />
                    </Card.Title>
                    <Card.Body style={{ fontSize: 14, fontWeight: 300 }}>
                      {c.description}
                    </Card.Body>
                    <Card.Footer>
                      <Button
                        block
                        variant="primary"
                        size="sm"
                        onClick={() => history.push(c.link)}
                      >
                        Go
                      </Button>
                    </Card.Footer>
                  </Card>
                </Col>
              );
            });
          })}
        </Row>
      </Container>
    </>
  );
}
